import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ProjectCreatorModel } from '../models/projectCreatorModel';
import { TeamMemberModel } from '../models/teamMemberModel';
import { UserModel } from '../models/userModel';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  BACK_URL = environment.hashBackApiUrl;

  userData: UserModel = {
    id: 0,
    name: null,
    username: null,
    password: null,
    token: null,
    fcm_token: null,
    active: null,
    create_on: null,
    update_on: null,
  };

  selectedProject: any = null;

  projectTeamMembers: TeamMemberModel[] | null = null;
  projectCreator: ProjectCreatorModel | null = null;
  projectMyAccess: any = null;

  constructor(
    private http: HttpClient,
    private platform: Platform
  ) {
    if (this.selectedProject && this.selectedProject.back_node_url) {
      this.BACK_URL = this.selectedProject.back_node_url;
    }
  }

  getProjectCreator() {
    return new Promise((resolve, reject) => {

      const params: any = {
        user_id: this.selectedProject.user_id
      };

      this.http.get(environment.hashBackApiUrl + '/project/creator', {
        params,
      }).subscribe({
        next: (response: any) => {
          if (response.success === true && response.result.length > 0) {
            response.result[0].role = 'SUPERADMIN';
            this.projectCreator = response.result[0];
            resolve(response.result[0]);
          } else {
            resolve(null);
          }
        },
        error: error => {
          reject(error);
        }
      });
    });

  }

  getProject(id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.hashBackApiUrl + '/data/project', {
        params: {
          where: `public_id = '${id}'`
        }
      }).subscribe({
        next: (result: any) => {
          if (result.success === true) {
            resolve(result.result[0]);
          } else {
            resolve(null);
          }
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
    return promise;
  }

  getProjectById(id: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.get(environment.hashBackApiUrl + '/data/project', {
        params: {
          where: `id = '${id}'`
        }
      }).subscribe({
        next: (result: any) => {
          if (result.success === true) {
            resolve(result.result[0]);
          } else {
            resolve(null);
          }
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
    return promise;
  }


  getTeamMember(name: string | null = null, userId = null): TeamMemberModel | undefined | null {
    let teamMember: TeamMemberModel | null | undefined = null;
    if (name) {
      teamMember = this.projectTeamMembers!!.find((member: any) => member.name.toLocaleUpperCase() === name.toLocaleUpperCase());
    } else if (userId) {
      teamMember = this.projectTeamMembers!!.find((member: any) => member.user_id === userId);
    }
    return teamMember;
  }

  /**
   * Device Tokens from team member devices
   */
  getTeamDeviceTokens(exclude = []) {
    const devicesTokens: any[] = [];
    this.projectTeamMembers!!.forEach((member: TeamMemberModel, index: number) => {
      if (member.fcm_token && (exclude.length > 0 && !exclude.find(userId => userId === member.user_id))) {
        devicesTokens.push(member.fcm_token);
      }
    });
    return devicesTokens;
  }

  getMemberDeviceTokens({ userId = 0, excludeUsers = [] }: any) {
    const devicesTokens: any[] = [];
    this.projectTeamMembers!!.forEach((member: TeamMemberModel, index: number) => {
      if (member.fcm_token) {
        if (userId) {
          if (member.user_id === userId) {
            devicesTokens.push(member.fcm_token);
          }
        } else if (excludeUsers) {
          if (excludeUsers.findIndex((excludeUserId: any) => excludeUserId !== member.user_id) > -1) {
            devicesTokens.push(member.fcm_token);
          }
        } else {
          devicesTokens.push(member.fcm_token);
        }
      }
    });
    return devicesTokens;
  }
}
